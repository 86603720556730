export function getDynamicFontSize(
  text: string,
  maxFontSize: number,
  minFontSize: number,
  minThreshold: number,
  maxThreshold: number,
): number {
  const textLength = text.length;

  if (textLength < minThreshold) {
    return maxFontSize;
  } else if (textLength < maxThreshold) {
    // Calculate font size proportionally between maxFontSize and minFontSize for text length
    const fontSize =
      maxFontSize -
      ((textLength - minThreshold) / (maxThreshold - minThreshold)) *
        (maxFontSize - minFontSize);
    return Math.round(fontSize);
  } else {
    return minFontSize;
  }
}
